import * as React from 'react'
import { createContext, useContext, useState } from 'react'

export type Search = {
  architect: string
  sort: string
}

interface SearchContextInterface {
  search: Search
  setSearch: (type: Search) => void
}

const SearchContext = createContext<SearchContextInterface>({
  search: { architect: '', sort: '施工順' },
  setSearch: (type: Search) => {},
})

export const useSearch = () => {
  return useContext(SearchContext)
}

type Props = {
  children: React.ReactNode
}

export const SearchContextProvider = ({ children }: Props) => {
  const [search, setSearch] = useState({
    architect: '',
    sort: '施工順',
  })
  return (
    <SearchContext.Provider value={{ search, setSearch }}>
      {children}
    </SearchContext.Provider>
  )
}
