import * as React from 'react'
import { createContext, useContext, useState } from 'react'

interface TagContextInterface {
  tag: string
  setTag: (type: string) => void
}

const TagContext = createContext<TagContextInterface>({
  tag: '全建築',
  setTag: (type: string) => {},
})

export const useTag = () => {
  return useContext(TagContext)
}

type Props = {
  children: React.ReactNode
}

export const TagContextProvider = ({ children }: Props) => {
  const [tag, setTag] = useState('全建築')
  return (
    <TagContext.Provider value={{ tag, setTag }}>
      {children}
    </TagContext.Provider>
  )
}
