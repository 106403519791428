import { LocationContextProvider } from './src/contexts/location'
import { SearchContextProvider } from './src/contexts/search'
import { TagContextProvider } from './src/contexts/tag'

export const wrapRootElement = ({ element }) => {
  return (
    <SearchContextProvider>
      <LocationContextProvider>
        <TagContextProvider>{element}</TagContextProvider>
      </LocationContextProvider>
    </SearchContextProvider>
  )
}
