import * as React from 'react'
import { createContext, useContext, useState } from 'react'

export type Location = {
  lat: number
  lng: number
  zoom: number
  currentPosition: boolean
}

interface LocationContextInterface {
  location: Location
  setLocation: (_Location: Location) => void
}

const LocationContext = createContext<LocationContextInterface>({
  location: { lat: 0, lng: 0, zoom: 0, currentPosition: false },
  setLocation: (_Location: Location) => {},
})

export const useLocation = () => {
  return useContext(LocationContext)
}

type Props = {
  children: React.ReactNode
}

export const LocationContextProvider = ({ children }: Props) => {
  const [location, setLocation] = useState({
    lat: 35.7102,
    lng: 139.8,
    zoom: 10,
    currentPosition: false,
  })
  return (
    <LocationContext.Provider value={{ location, setLocation }}>
      {children}
    </LocationContext.Provider>
  )
}
